<template><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enable-background="new 0 0 64 64" :width="width" :height="height"><g :fill="fill"><path d="M56.6 7.9c0-3.7-3-6.6-6.6-6.6H14c-3.7 0-6.6 3-6.6 6.6v52c0 .6.4 1.2.9 1.5s1.3.3 1.8-.1l5.5-3.7 7.2 4.8c.6.4 1.4.4 1.9 0l7.2-4.8 7.2 4.8c.3.2.6.3 1 .3.3 0 .7-.1 1-.3l7.2-4.8 5.5 3.7c.5.4 1.2.4 1.8.1.6-.3.9-.9.9-1.5l.1-52zm-6.4 46.8c-1.1-.8-2.7-.8-3.8 0l-6.3 4.2-6.3-4.2c-.6-.4-1.2-.6-1.9-.6s-1.3.2-1.9.6l-6.3 4.2-6.3-4.2c-1.2-.8-2.6-.8-3.8 0l-2.8 1.9V7.9c0-1.7 1.4-3.1 3.1-3.1h35.9c1.7 0 3.1 1.4 3.1 3.1v48.7l-2.7-1.9z"></path><path d="M28.1 11.2h-8.9c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h8.9c1 0 1.8-.8 1.8-1.8s-.9-1.8-1.8-1.8z"></path><path d="M44.3 11.2h-2.4c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h2.4c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8z"></path><path d="M19.1 26h5.7c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8h-5.7c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8z"></path><path d="M44.3 22.5h-2.4c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h2.4c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8z"></path><path d="M28.1 33.9h-8.9c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h8.9c1 0 1.8-.8 1.8-1.8s-.9-1.8-1.8-1.8z"></path><path d="M44.3 33.9h-2.4c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h2.4c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8z"></path><path d="M24.8 45.3h-5.7c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h5.7c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8z"></path><path d="M44.3 45.3h-2.4c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h2.4c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8z"></path></g></svg></template>

<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: "100%"
		},
		height: {
			type: [Number, String],
			default: "100%"
		},
		strokewidth: {
			type: [Number, String],
			default: 1
		},
		fill: {
			type: String,
			default: "currentColor"
		},
		secondaryfill: {
			type: String
		}
	},
	computed: {
		_secondaryfill: function () {
			return this.secondaryfill || this.fill
		}
	}
}
</script>